var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{style:({
      position: 'relative',
      margin: 'auto',
      textAlign: 'center',
      maxWidth: '900px'
    })},[_c('img',{style:({ width: '30vh' }),attrs:{"alt":"bohm-logo","src":require("@/../public/img/logo.svg")}}),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('UserSearch',{ref:"userSearch",attrs:{"returnObject":""},on:{"employee-selected":_vm.handleEmployee}}),_c('v-textarea',{staticClass:"mt-4",attrs:{"filled":"","label":"Type your suggestion here...","dark":"","color":"secondary"},model:{value:(_vm.suggestion),callback:function ($$v) {_vm.suggestion=$$v},expression:"suggestion"}}),_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-btn',{staticClass:"mx-2",attrs:{"dark":""},on:{"click":_vm.clear}},[_vm._v("Clear")]),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"secondary","type":"submit"}},[_vm._v(" Submit ")])],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":2000,"absolute":"","right":"","color":"success"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" Suggestion Submitted! ")]),_c('v-snackbar',{attrs:{"timeout":2000,"absolute":"","right":"","color":"error"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.errorSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.errorSnackbar),callback:function ($$v) {_vm.errorSnackbar=$$v},expression:"errorSnackbar"}},[_vm._v(" Please fill out the form with all required fields. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }