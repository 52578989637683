<template>
  <v-container>
    <div
      :style="{
        position: 'relative',
        margin: 'auto',
        textAlign: 'center',
        maxWidth: '900px'
      }"
    >
      <img
        alt="bohm-logo"
        src="@/../public/img/logo.svg"
        :style="{ width: '30vh' }"
      />
      <v-form @submit.prevent="submit">
        <UserSearch
          returnObject
          @employee-selected="handleEmployee"
          ref="userSearch"
        />
        <v-textarea
          class="mt-4"
          filled
          v-model="suggestion"
          label="Type your suggestion here..."
          dark
          color="secondary"
        >
        </v-textarea>
        <v-layout row wrap justify-center>
          <v-btn class="mx-2" dark @click="clear">Clear</v-btn>
          <v-btn color="secondary" class="mx-2" type="submit"> Submit </v-btn>
        </v-layout>
      </v-form>
    </div>

    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      absolute
      right
      color="success"
    >
      Suggestion Submitted!
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="errorSnackbar"
      :timeout="2000"
      absolute
      right
      color="error"
    >
      Please fill out the form with all required fields.
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="errorSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import UserSearch from "../components/UserSearch";

export default {
  name: "Home",
  data: () => ({
    user: null,
    suggestion: "",
    snackbar: false,
    errorSnackbar: false
  }),
  components: {
    UserSearch
  },

  mounted() {
    this.clear();
  },

  methods: {
    handleEmployee(e) {
      try {
        this.user = e.displayName;
      } catch (error) {
        this.user = null;
      }
    },

    clear() {
      this.user = null;
      this.suggestion = "";
      this.$refs.userSearch.clear();
    },
    async submit() {
      if (this.user && this.suggestion) {
        await this.$store.dispatch("sendEmail", {
          user: this.user,
          suggestion: this.suggestion
        });
        this.snackbar = true;
        this.clear();
      } else {
        this.errorSnackbar = true;
      }
    }
  }
};
</script>
