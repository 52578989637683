<template>
  <v-autocomplete
    dark
    v-model="select"
    :items="users"
    :search-input.sync="search"
    :item-text="createSelectLabel"
    :item-value="createSelectLabel"
    color="secondary"
    ref="auto"
    :label="text ? text : 'Employee Name..'"
    :return-object="returnObject"
    filled
    full-width
    hide-details
    large
    prepend-inner-icon="search"
    hide-no-data
    clearable
  ></v-autocomplete>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "UserSearch",

  data() {
    return {
      select: null,
      search: null
    };
  },
  props: {
    text: { type: String },
    returnObject: { type: Boolean }
  },

  computed: {
    ...mapGetters({
      users: "getUsers"
    })
  },

  methods: {
    ...mapActions({
      userSearch: "getUsers"
    }),

    createSelectLabel(e) {
      return `${e.displayName}`;
    },
    clear() {
      this.search = null;
      this.$refs.auto.clearableCallback();
    }
  },

  watch: {
    select(employee) {
      this.$emit("employee-selected", employee);
    },

    search(value) {
      if (value) this.userSearch(value);
    }
  }
};
</script>
